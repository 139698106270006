import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/small-logo.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
// import twitter from '../img/social/twitter.svg'
// import linkedin from "../img/social/linkedin.svg";

const Footer = class extends React.Component {
  render() {
    const locale = this.props.locale
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <Link to="/">
                <img src={logo} alt="HIRI TECH" />
              </Link>
            </div>
            <div className="column is-8">
              <section className="menu">

              {locale === "en" ? (
              <ul className="menu-list">
                  <li>
                      <Link className="navbar-item" to="/about" activeClassName="active" title="About Us">About Us</Link>
                  </li>
                  <li>
                      <Link className="navbar-item" to="/services" activeClassName="active" title="Services">Services</Link>
                  </li>
                  <li>
                      <Link className="navbar-item" to="/blog" activeClassName="active" title="News">News</Link>
                  </li>
                  <li>
                      <Link className="navbar-item" to="/careers" activeClassName="active" title="Careers">Careers</Link>
                  </li>
                  <li>
                      <Link className="navbar-item" to="/contact" activeClassName="active" title="Contact Us">Contact Us</Link>
                  </li>
                  <li>
                      <Link  className="navbar-item" to="/tr" title="Türkçe">TR</Link>
                  </li>
              </ul>
              ) : (
              <ul className="menu-list">
                  <li>
                      <Link className="navbar-item" to="/tr/about" activeClassName="active" title="Hakkımızda">Hakkımızda</Link>
                  </li>
                  <li>
                      <Link className="navbar-item" to="/tr/services" activeClassName="active" title="Hizmetler">Hizmetler</Link>
                  </li>
                  <li>
                      <Link className="navbar-item" to="/tr/blog" activeClassName="active" title="Haberler">Haberler</Link>
                  </li>
                  <li>
                      <Link className="navbar-item" to="/tr/careers" activeClassName="active" title="Kariyer">Kariyer</Link>
                  </li>
                  <li>
                      <Link className="navbar-item" to="/tr/contact" activeClassName="active" title="Bize Ulaşın">Bize Ulaşın</Link>
                  </li>
                  <li>
                      <Link  className="navbar-item" to="/" title="English">EN</Link>
                  </li>
              </ul>
              )}
              </section>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container">
            <div className="columns">
              <div className="column is-5">
                <h4>ABOUT US</h4>
                <p>
                  More than 90% of the Blockchain investments flow to scams which seem perfectly professional to the eye of an ordinary investor. We are technology professionals leading modest investments in Blockchain markets to guarantee real sustainable profits.
                </p>
              </div>
              <div className="column is-6 is-offset-1 social">
                <h4>CONNECT WITH US</h4>
                <a
                  title="Facebook"
                  href="https://www.facebook.com/HiriTech-322636951944736"
                >
                  <img src={facebook} alt="Facebook" />
                </a>
                {/* <a title="Twitter" href="https://twitter.com">
                  <img src={twitter} alt="Twitter" />
                </a> */}
                <a
                  title="Instagram"
                  href="https://www.instagram.com/hiritech/"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
                {/* <a title="Linkedin" href="https://linkedin.com">
                  <img src={linkedin} alt="Linkedin" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer
