import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo.png";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };
/* eslint-disable */
  render() {
    const locale = this.props.locale
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to={ locale === "en" ? "/" : "/tr" } className="navbar-item padding-0" title="HIRI TECH">
              <img src={logo} alt="HIRI TECH" style={{ width: "220px" }} />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${
                this.state.navBarActiveClass
              }`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
              {locale === "en" ? (
                <div className="navbar-start has-text-centered">
                  <Link className="navbar-item" to="/about" activeClassName="active" title="About Us">ABOUT US</Link>
                  <Link className="navbar-item" to="/services" activeClassName="active" title="Services">SERVICES</Link>
                  <Link className="navbar-item" to="/blog" activeClassName="active" title="News">NEWS</Link>
                  <Link className="navbar-item" to="/careers" activeClassName="active" title="Careers">CAREERS</Link>
                  <Link className="navbar-item" to="/contact" activeClassName="active" title="Contact Us">CONTACT US</Link>
                  <div className="navbar-item"><Link to="/tr" title="Türkçe">TR</Link></div>
                </div>
                ) : (
                <div className="navbar-start has-text-centered">
                  <Link className="navbar-item" to="/tr/about" activeClassName="active" title="Hakkımızda">HAKKIMIZDA</Link>
                  <Link className="navbar-item" to="/tr/services" activeClassName="active" title="Hizmetler">HİZMETLER</Link>
                  <Link className="navbar-item" to="/tr/blog" activeClassName="active" title="Haberler">HABER</Link>
                  <Link className="navbar-item" to="/tr/careers" activeClassName="active" title="Kariyer">KARİYER</Link>
                  <Link className="navbar-item" to="/tr/contact" activeClassName="active" title="Bize Ulaşın">BİZE ULAŞIN</Link>
                  <div className="navbar-item"><Link to="/" title="English">EN</Link></div>
                </div>
                )}
              
              
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
